module.exports = (function anonymous(locals, filters, escape, rethrow) {
escape = escape || function (html){
  return String(html)
    .replace(/&(?!#?[a-zA-Z0-9]+;)/g, '&amp;')
    .replace(/</g, '&lt;')
    .replace(/>/g, '&gt;')
    .replace(/'/g, '&#39;')
    .replace(/"/g, '&quot;');
};
var buf = [];
with (locals || {}) { (function(){ 
 buf.push('<div class="a0-notloggedin a0-mode">\n    <form novalidate>\n      <div class="a0-collapse-social">\n        <div class="a0-iconlist a0-hide"><p class="a0-hide">', escape((4,  i18n.t('signin:or') )), '</p></div>\n        <div class="a0-separator a0-hide"><span>', escape((5,  i18n.t('signin:separatorText') )), '</span></div>\n      </div>\n      <div class="a0-corporate-credentials a0-hide">Please enter your <strong>corporate</strong> credentials at <span class="a0-domain"></span>.</div>\n      <div class="a0-emailPassword">\n        <div class="a0-inputs">\n\n          <div class="a0-email">\n              ');12;
                var emailPlaceholder = i18n.t('signin:emailPlaceholder')
                  + ( _isUsernameRequired() ? ' / ' + i18n.t('signin:usernamePlaceholder') : '' )
                var inputBudicon = _isUsernameRequired() || 'username' === usernameStyle ? '1' : '5';
              ; buf.push('\n              <label for="a0-signin_easy_email" class="a0-sad-placeholder">\n                ', escape((18,  emailPlaceholder )), '\n              </label>\n\n              <div class="a0-input-box">\n                <i class="a0-icon-budicon-', escape((22,  inputBudicon )), '"></i>\n\n                <input name="email"\n                       id="a0-signin_easy_email"\n                       type="email"\n                       placeholder="', escape((27,  emailPlaceholder )), '"\n                       title="', escape((28,  emailPlaceholder )), '">\n              </div>\n          </div>\n\n          <div class="a0-password a0-hide">\n            <label for="a0-signin_easy_password" class="a0-sad-placeholder">\n              ', escape((34,  i18n.t('signin:passwordPlaceholder') )), '\n            </label>\n\n            <div class="a0-input-box">\n              <i class="a0-icon-budicon"></i>\n\n              <input name="password"\n                     id="a0-signin_easy_password"\n                     type="password"\n                     placeholder="', escape((43,  i18n.t('signin:passwordPlaceholder') )), '"\n                     title="', escape((44,  i18n.t('signin:passwordPlaceholder') )), '">\n            </div>\n          </div>\n\n        </div>\n        <!-- .a0-inputs -->\n\n        <div class="a0-sso-notice-container a0-hide"><i class="a0-icon-budicon"></i> <span class="a0-sso-notice">Single Sign-on enabled</span></div>\n\n        <div class="a0-action">\n            <button type="submit" class="a0-primary a0-next">', escape((54,  i18n.t('signin:action') )), '</button>\n            <div class="a0-db-actions">\n            </div>\n        </div>\n      </div>\n    </form>\n</div>\n'); })();
} 
return buf.join('');
})