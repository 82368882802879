module.exports = (function anonymous(locals, filters, escape, rethrow) {
escape = escape || function (html){
  return String(html)
    .replace(/&(?!#?[a-zA-Z0-9]+;)/g, '&amp;')
    .replace(/</g, '&lt;')
    .replace(/>/g, '&gt;')
    .replace(/'/g, '&#39;')
    .replace(/"/g, '&quot;');
};
var buf = [];
with (locals || {}) { (function(){ 
 buf.push('<div class="a0-loading a0-mode">\n    ');2; if (locals.alt_spinner) { ; buf.push('\n    <div class="a0-spinner a0-spin-img" style="background-image: url(\'', escape((3,  locals.alt_spinner )), '\')"></div>\n    ');4; } else { ; buf.push('\n    <div class="a0-spinner a0-spin-container">\n        <div class="a0-spinner-css">\n          <span class="a0-side a0-sp_left">\n            <span class="a0-fill"></span>\n          </span>\n          <span class="a0-side a0-sp_right">\n            <span class="a0-fill"></span>\n          </span>\n        </div>\n        <div class="a0-spin-message">\n            <span>&nbsp;</span>\n        </div>\n    </div>\n    ');18; } ; buf.push('\n</div>\n'); })();
} 
return buf.join('');
})